.quiz {
  margin: 25px 0;
  .quizTitle {
    text-align: center;
    font-size: 2rem;
  }
  
  .quizButton {
    width: 100%;
    height: 2.5rem;
    display: inline-block;
    margin-bottom: 10px;
  }
}

.showAnswerButton {
  background-color: #2e67fb;
  border: 1px solid #2e67fb;

  &:hover {
    background-color: #2e67fb;
    border: 1px solid #2e67fb;
  }

  &:focus {
    background-color: #2e67fb;
    border: 1px solid #2e67fb;
  }
}

.info {
  padding: 25px 0;
  text-align: center;
  .time {
    font-weight: bold;
  }
}

.subscribe-modal {
  text-align: center;

  .email {
    input {
      padding-left: 10px;
    }
  }

  .sub, .no-sub, .email {
    width: 100%;
    margin-bottom: 10px;
  }

  .no-sub {
    background-color: #2e67fb;
    border: 1px solid #2e67fb;
  }
}
