$fail: #D8000C;
$good: #FFCC11;
$perfect: #1DA57A;

.score {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  text-align: center;

  .thankYou {
    font-size: 3rem;
    margin: auto;
  }

  .label {
    font-size: 4.5rem;
    margin: auto;
  }

  .theScore {
    font-size: 5rem;
    margin: auto;

    &.fail {
      color: $fail;
    }

    &.good {
      color: $good;
    }

    &.perfect {
      color: $perfect;
    }
  }
}