.chart {
  .barChartCard {

    height: 350px;
    padding: 0 60px 0 0px;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 700px) {
      height: 250px;
      padding: 0 25px 0 0;
    }

    .ant-card-body {
      height: 100%;
      width: 100%;
    }
  }
  .recharts-wrapper {
    display: inline-block;
  }
}