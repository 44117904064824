.loading {
  width: 100%;
  height: 100%;
  display: flex;
  img {
    height: 200px;
    margin: auto;
    animation: spin 2.5s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}

@keyframes spin {
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(360deg);
  }
}