@import '../../colors.scss';

.admin {
  margin-top: 50px;
  text-align: center;
}

.backButton {
  float: left;
  font-size: 2rem;
  padding: 2rem;
  position: relative;
  z-index: 1000;
  
  &:hover {
    color: $primary;
    transition: .125s ease-in;
  }
}

.quizTable {

  @media only screen and (max-width: 900px) {
    table {
      table-layout: fixed;
    }
  }
}