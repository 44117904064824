$optionSpace: .5rem;
$optionTextSize: 1.2rem;

$error: #D8000C;
$errorTextSize: 1.2rem;

$answerColor: rgba(0, 178, 0, .5);

.question {
  margin: 3rem 0;
  padding: 1rem;
  
  &.error {
    border-radius: .5rem;
    border: 3px dotted $error;
  }
}

.checkbox-group {
  .checkbox {
    width: 100%;
    margin-bottom: $optionSpace;
    font-size: $optionTextSize;
    margin-left: 0;
    padding-left: 25px;

    &.answer {
      background-color: $answerColor;
      border-radius: 10px;
    }
  }
}

.questionLabel {
  margin-bottom: 1.5rem;
  .label {
    font-size: 1.5rem;
  }

  .multipleChoice {
    display: block;
    font-style: italic;
    font-size: .9rem;
  }
}

.radio {
  width: 100%;
  margin-bottom: $optionSpace;
  padding: 1px 0px 1px 25px;

  span {
    font-size: $optionTextSize;
  }

  &.answer {
    background-color: $answerColor;
    border-radius: 10px;
  }
}

.errorText {
  color: $error;
  text-align: center;
  font-size: $errorTextSize;
  font-weight: bold;
}