.theHeader {
  display: flex;
  align-items: center;
  padding: 0 5%;

  .logo {
    flex: 1;
    height: 100%;
    width: 100%;
    display: inline-block;
    text-align: left;

    img {
      height: 75%;
    }
  }

  .profile {
    color: white;
    margin: 0;
    height: 100%;
    flex: 2;
    text-align: center;

    @media only screen and (max-width: 600px) {
      font-size: 80%;
    }

    @media only screen and (max-width: 450px) {
      font-size: 60%;
    }
  }

  .control {
    flex: 1;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
    justify-content: flex-end;

    height: 100%;

    .signOut {
      text-align: center;
    }
  }
}


.signInForm {
  input {
    margin-bottom: .5rem;
  }
  
  .error {
    text-align: center;
    font-weight: bold;
  }

  Button {
    width: 100%;
  }
}