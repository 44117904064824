@import url("https://fonts.googleapis.com/css?family=Comfortaa|Montserrat&display=swap");
@import './colors.scss';

h1,
h2,
h3 {
  font-family: "Comfortaa", cursive;
}

p,
span,
label {
  font-family: "Montserrat", sans-serif;
}

#root {
  min-height: 100%;
  max-height: 100%;
  height: 100%;
}

.quiz {
  height: 100%;
  display: flex;
}

.error { 
  color: $error;
}
