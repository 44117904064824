@import url(https://fonts.googleapis.com/css?family=Comfortaa|Montserrat&display=swap);
.loading {
  width: 100%;
  height: 100%;
  display: flex; }
  .loading img {
    height: 200px;
    margin: auto;
    -webkit-animation: spin 2.5s;
            animation: spin 2.5s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear; }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  to {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg); } }

@keyframes spin {
  from {
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg); }
  to {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg); } }

.question {
  margin: 3rem 0;
  padding: 1rem; }
  .question.error {
    border-radius: .5rem;
    border: 3px dotted #D8000C; }

.checkbox-group .checkbox {
  width: 100%;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  margin-left: 0;
  padding-left: 25px; }
  .checkbox-group .checkbox.answer {
    background-color: rgba(0, 178, 0, 0.5);
    border-radius: 10px; }

.questionLabel {
  margin-bottom: 1.5rem; }
  .questionLabel .label {
    font-size: 1.5rem; }
  .questionLabel .multipleChoice {
    display: block;
    font-style: italic;
    font-size: .9rem; }

.radio {
  width: 100%;
  margin-bottom: 0.5rem;
  padding: 1px 0px 1px 25px; }
  .radio span {
    font-size: 1.2rem; }
  .radio.answer {
    background-color: rgba(0, 178, 0, 0.5);
    border-radius: 10px; }

.errorText {
  color: #D8000C;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold; }

.score {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 50%;
  text-align: center; }
  .score .thankYou {
    font-size: 3rem;
    margin: auto; }
  .score .label {
    font-size: 4.5rem;
    margin: auto; }
  .score .theScore {
    font-size: 5rem;
    margin: auto; }
    .score .theScore.fail {
      color: #D8000C; }
    .score .theScore.good {
      color: #FFCC11; }
    .score .theScore.perfect {
      color: #1DA57A; }

.quiz {
  margin: 25px 0; }
  .quiz .quizTitle {
    text-align: center;
    font-size: 2rem; }
  .quiz .quizButton {
    width: 100%;
    height: 2.5rem;
    display: inline-block;
    margin-bottom: 10px; }

.showAnswerButton {
  background-color: #2e67fb;
  border: 1px solid #2e67fb; }
  .showAnswerButton:hover {
    background-color: #2e67fb;
    border: 1px solid #2e67fb; }
  .showAnswerButton:focus {
    background-color: #2e67fb;
    border: 1px solid #2e67fb; }

.info {
  padding: 25px 0;
  text-align: center; }
  .info .time {
    font-weight: bold; }

.subscribe-modal {
  text-align: center; }
  .subscribe-modal .email input {
    padding-left: 10px; }
  .subscribe-modal .sub, .subscribe-modal .no-sub, .subscribe-modal .email {
    width: 100%;
    margin-bottom: 10px; }
  .subscribe-modal .no-sub {
    background-color: #2e67fb;
    border: 1px solid #2e67fb; }

.theHeader {
  display: flex;
  align-items: center;
  padding: 0 5%; }
  .theHeader .logo {
    flex: 1 1;
    height: 100%;
    width: 100%;
    display: inline-block;
    text-align: left; }
    .theHeader .logo img {
      height: 75%; }
  .theHeader .profile {
    color: white;
    margin: 0;
    height: 100%;
    flex: 2 1;
    text-align: center; }
    @media only screen and (max-width: 600px) {
      .theHeader .profile {
        font-size: 80%; } }
    @media only screen and (max-width: 450px) {
      .theHeader .profile {
        font-size: 60%; } }
  .theHeader .control {
    flex: 1 1;
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
    justify-content: flex-end;
    height: 100%; }
    .theHeader .control .signOut {
      text-align: center; }

.signInForm input {
  margin-bottom: .5rem; }

.signInForm .error {
  text-align: center;
  font-weight: bold; }

.signInForm Button {
  width: 100%; }

.chart .barChartCard {
  height: 350px;
  padding: 0 60px 0 0px;
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 700px) {
    .chart .barChartCard {
      height: 250px;
      padding: 0 25px 0 0; } }
  .chart .barChartCard .ant-card-body {
    height: 100%;
    width: 100%; }

.chart .recharts-wrapper {
  display: inline-block; }

.admin {
  margin-top: 50px;
  text-align: center; }

.backButton {
  float: left;
  font-size: 2rem;
  padding: 2rem;
  position: relative;
  z-index: 1000; }
  .backButton:hover {
    color: #1DA57A;
    -webkit-transition: .125s ease-in;
    transition: .125s ease-in; }

@media only screen and (max-width: 900px) {
  .quizTable table {
    table-layout: fixed; } }

h1,
h2,
h3 {
  font-family: "Comfortaa", cursive; }

p,
span,
label {
  font-family: "Montserrat", sans-serif; }

#root {
  min-height: 100%;
  max-height: 100%;
  height: 100%; }

.quiz {
  height: 100%;
  display: flex; }

.error {
  color: #D8000C; }

